import { getQueryKey } from "@core/hooks/useSession";
import getServerSession from "@core/utils/getServerSession";
import { AuthenticationFormVariants } from "@features/auth/components/AuthenticationForm";
import { AuthenticationScreen } from "@features/auth/screens/AuthenticationScreen";
import { GetServerSideProps } from "next";
import { NextSeo } from "next-seo";
import { QueryClient } from "react-query";
import { dehydrate } from "react-query/hydration";

const PAGE_TITLE = "Log in";

interface Props {
  variant: AuthenticationFormVariants;
}

export default function LoginPage({ variant }: Props): JSX.Element {
  return (
    <>
      <NextSeo
        title={PAGE_TITLE}
        openGraph={{
          title: PAGE_TITLE,
        }}
        canonical={`${process.env.NEXT_PUBLIC_CANONICAL_URL}/login`}
      />

      <AuthenticationScreen variant={variant} />
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const queryClient = new QueryClient();
  const session = await getServerSession(ctx);

  // Pre-load the session query
  queryClient.setQueryData(getQueryKey(), session);

  const { query } = ctx;
  const { redirectTo } = query;
  const normalizedRedirectTo = Array.isArray(redirectTo)
    ? redirectTo[0]
    : redirectTo;

  const nextUrl =
    typeof normalizedRedirectTo === "string" &&
    normalizedRedirectTo.startsWith("/")
      ? normalizedRedirectTo
      : "/";

  let variant: AuthenticationFormVariants = "default";

  if (nextUrl.includes("conqueror_post")) {
    variant = "conqueror";
  }

  if (session) {
    return {
      redirect: {
        destination: nextUrl,
        permanent: false,
      },
    };
  }

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
      variant,
    },
  };
};

// The page is publicly accessible, so we want to show the OneTrust consent
// dialog to the user if they are not logged in.
LoginPage.shouldShowConsentDialog = true;
